<template>
	<div>
		<v-app-bar color="#90A4AE">
			<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

			<v-toolbar-title style="font-size: 30px"
				>Nombre de la Empresa</v-toolbar-title
			>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" absolute temporary>
			<v-list nav dense>
				<v-list-item-group
					v-model="group"
					active-class="deep-purple--text text--accent-4">
					<v-list-item>
						<v-list-item-icon>
							<v-icon>mdi-home</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Contacto</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<div>
			<v-carousel
				:show-arrows="false"
				:light="true"
				cycle
				:interval="6000"
				style="
					width: 1300px;
					height: 500px;
					margin-left: 100px;
					margin-top: 5px;
				">
				<v-carousel-item
					v-for="(item, index) in image"
					:key="'carousel-' + index"
					:src="item.src"
					reverse-transition="scroll-x-transition"
					transition="fab-transition"></v-carousel-item>
			</v-carousel>
		</div>
		<div style="margin-left: 100px; margin-top: 20px">
			<v-card class="mx-auto" max-width="500">
				<v-container fluid>
					<v-row dense>
						<v-col
							v-for="(card, index) in cards"
							:key="'card-' + index"
							:cols="card.flex">
							<v-card>
								<v-img
									:src="card.src"
									class="white--text align-end"
									gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
									height="200px">
									<v-card-title
										v-text="card.title"
										style="color: white"></v-card-title>
								</v-img>

								<v-card-actions>
									<v-spacer></v-spacer>

									<v-btn icon>
										<p style="color: black">
											Lo quiero :
											<v-icon style="margin-right: 110px">mdi-cart</v-icon>
										</p>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</div>
		<div>
			<v-card
				style="
					height: 540px;
					width: 750px;
					margin-left: 650px;
					margin-top: -540px;
				"
				elevation="2"
				outlined>
				<v-card-title
					>Hola mi nombre es Freddy
					<v-img style="margin-left: 600px">Pegar Foto</v-img>
				</v-card-title>
				<v-card-text style="max-width: 590px; font-size: 1.25rem; color: black"
					>Me dedico a realizar muebles hecho de melamina, he aqui varias de mis
					proyectos realizados. Muebles que puedes pedir :

					<v-list>
						<v-list-item-group v-model="model">
							<v-list-item v-for="(item, i) in items" :key="i">
								<v-list-item-icon>
									<v-icon v-text="item.icon"></v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title
										v-text="item.text"
										style="margin-left: 20px"></v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-card-text>
			</v-card>
		</div>
		<v-footer color="#90A4AE" height="70" padless style="margin-top: 20px">
			<v-row>
				<v-col col="4" style="text-align: right">
					<p class="font-weight-black" style="font-size: 1.5rem">
						Medios de Contacto :
					</p>
				</v-col>
				<v-col col="8" style="align-items: center">
					<v-btn style="margin-right: 10px" class="ma-2" dark color="indigo">
						<v-icon size="24px">mdi-facebook</v-icon>
					</v-btn>
					<v-btn style="margin-right: 10px" class="ma-2" dark color="indigo">
						<v-icon>mdi-whatsapp</v-icon>
					</v-btn>
					<v-btn style="margin-right: 10px" class="ma-2" dark color="indigo">
						<v-icon>mdi-instagram</v-icon>
					</v-btn>
					<v-btn style="margin-right: 10px" class="ma-2" dark color="indigo">
						<v-icon>mdi-gmail</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-footer>
	</div>
</template>

<script>
	export default {
		name: "App",

		data: () => ({
			drawer: false,
			group: null,
			items: [
				{
					icon: "mdi-inbox",
					text: "Veladores",
				},
				{
					icon: "mdi-star",
					text: "Closets",
				},
				{
					icon: "mdi-send",
					text: "Comoda",
				},
				{
					icon: "mdi-email-open",
					text: "Roperos",
				},
			],
			model: 1,
			cards: [
				{
					title: "",
					src: "https://lh3.googleusercontent.com/pw/AIL4fc8OK31UdfOSvdYzzYjvC2yIDv8wf8xjaW2AoENNsE8ECct5GZ0zCp0udnC9w7CJv37s-5J9EYGB8r_XypgQZ9ExFCYXVvIeWK38ngPXepp-TLB30GXZhQFi_EqX5v22KlS1gy6Rt4pA7HQ1EkNvrsHJdK9PO72LvE6ExTl8XQ7Kdn4J3YmzxZhwSeV13AHs0fBaqdkcoQmc2vhcj-bna9JnKDyQCH4r4AyjqAPhL-6sRCDYXCLb06gk-gy0F6WrcimvoWEhMOX9eV6m87GGlyoeWz-HjPETprlu2qf4ikwH9asTwxbrjA2dJzsz16U1uHv_QpEIrFXdZ-cmOpEHhLWbF1C5axxsSiLueZK6Q_5ujZabMRAkHcnwjtIdBqzbcYo8B22SOzQ8QIBsahjniPQ7W85FYc-cW15BNkuClZuRPJzhxBhEH4uq0Uye7Y_TO6gHv84gwqvYn6cINyJNh49qvONvIoJzGKD7IdokHiVG79zpYTjFLFi0FkoTxBLYfZ4bdkMh0Kn6IBnAPlgCC1fwrlNTyQtk2jVCZxNGJ_3nVrSuRnQBVF7NkKDtoAnobipMsWtr1ye1bt8XTRTNhebOErfRACqzHp-dUjw0MmnwNtW9Wf5mCmmrjqJS8gY91guyNl5bluunB1zal6Dks48wq7QfIEA0kqWQAaIpA2IV-UoxL5ja7PwsGjXaossYJptM0Mb-eyqA4mO-FanXHqCwhs-92MQtLAnckO6YJOfW-oazWF2lEpdbzN3mPwquRL2TDjXIWg848V0iq3ODnSJeWOhdsqkgqptL3tl1F94zeqsXiAbAVh3eJUpJ_DEhGbhg1F-v_GCmCaIFdVbS8Zgq573lbmaKHR6iDMypkwKKA1czd1Y7yUWS-vnsSyGSI8t5A0JLAzvC3SIXoCj0A_EIUmW18N73tnW3NaHfn2fxsdgFu9pZIKipLkA=w596-h251-s-no?authuser=2",
					flex: 12,
				},
				{
					title: "",
					src: "https://lh3.googleusercontent.com/pw/AIL4fc_mwom7vX_s8Vg23Z6Bwk2LKxCysV4-P9O9Pd9t7__XD9Dh0XoMk8W3NFCM9dAdeq4UfIuPFNbWO7jH0GoZxnAK6QFju1MEe6KKdvO4PXwxhMRBPNKk0qRQzdVjNqFzfc5VVHZul1jqtMEnfaH71u9YbZeEEwCOhy9eFZX9rEjwaeKfF6RGMtdgrsZUx6ATU8wRjxB6bxtHVeUx4-iNEmtN9WpWxzopSoLybGmGRtU_blxcgOC5EBarQganA-YWZjkf3R8G6XX_w7LJRQsDW43KfXK8mll6yNFqnzRCuTo_r5s8duogPdcn-wwuHb6XhydI7tIlh09Og0kjUaOFLfeuruO4cpFpbrWKQixXIsrPKMLzlEHrr7LR__eaFqLFjCdiBU29UIW4e0vS9Co8_nurZu4TxkSOOmYAKJd5i1IZsUpY2_EG_o3rSImQpDtaS2MM3X-Pr0trefNTA_sj8MD6n6AjJfvMKZI8sN1OPQpUYwZvXyKfDvgUos4ISpYPLTuErk-9hfxWgCpCerveZbnBQzAO5LRNZj3bMjeAjjmYDrdHxklFRTdYjp-oktJ0XhXZPX3cZb4iKKK3sy_1mBpD_GKqQ00LyOWfeLgpJEOp3RSBaK3RqRZ-q_79vDI5pKerMjzbUsYOYhjDduYfOj1y-M8Lu3H_DfQD200M1u0MPMUs7YVOWMrglzd4ukYmbm3IudEBaPzGRqob3RYedBqtNdyCqZQwL0sOSHtjMWO1nTlNgjXCE3YFhACSFN0nv7DWq8Fp1kzjfgY3SGX2syZSqsRRPG7iC8JiSDP9c4hHHCnoDnf1uYHX5bO9y-WROyu6TNO5m9G9uSDUhnoWkNab3SqMYnw3tnB_5Kq9AFnBgywLf6tZpawPN8Uq0P2f8BdbmXWF-F8qOf_GwVHrk6vwhCW_c3jsu40tgs2jnr-PhpebUaF7dEBXzgo=w294-h251-s-no?authuser=2",
					flex: 6,
				},
				{
					title: "",
					src: "https://lh3.googleusercontent.com/pw/AIL4fc-BuBBdDgCqAmJXCLejQyPS9gpLC57ycjZ9PeofyGD_P1YhTJgrZ23Hds_KXS1CaNSywavqjwW-ALJJAucqNG_cLQi8ZhxHl7YO2SmsCn2HaXERRcoVcU95uKtshg-Uaf8lb5GLKE0sVIe2i_ZmB1bWnlVjnGJl1WslSBo3xLEPHIwUUmuSDQYDHHOKFUq931p_AvoTsvOzdFntcWjlkU3rK9IO_tUUhPQZ0a32tT6L3TQaWfC5XcOvCUqT5faceMFM9OGAX2vFujZRPWeUF8OG2-zvvlHREQ0A9IANDHox18ppY_Y8BejFeu3gv2zKTO8b-k1qqVFjra8Vs53biY1DuLrbe4j9E9dH6dTP2iIMp9Byc8meUt3COd_2pU8giH_oaCFoYmqDdI9LrNRQ9mVUeTPQLgtJhPQzzKeHsrKmFBwKQ2PQ8fPTEurXqeVhk6a0mWWwmh6rKji3iRgC79BWLXCScFkDeaWYDqWk3kPsRlf-_o1ePi6PlatUzXz_71ZxmWCV9oMgYrOAC48NXP91X1CneZ1DYUY4Jjxfwy8U-p06_poZjDxuLC27tqx25JoLgVllIsGEZ_oQ0nQfa1BjeDdKKN5g-JsCmfIQuAoz6Be3ym72pfCdphyfWhV_xT0bJvUmpHazchpVpoC0AjkJhH8gl-ZRXciKit2oKlSwgOvMvKPqrSK5NlDv0Lck-po-91VyKgVjwd97VUJm_bdALD3XQfrr5DSJdCKJv--vGte41WGSP_wU6cRUmaxaHuejAsAwbOA8W_v-zcMAY8XThEt3AwA1ThO7em_k9M-pA3EbC1obISk_mOF_1fazAl3-i8_cSLFMYNSLXSVWfk_kK_ayRt14B9BOrkM2GnuuqjQ-KHECvfGdzGaSjfPW-yEp12CqTLnfZOGvQr32fHXUJaEEyoTve7LYsBlqxi-qet_XGAajy6Ga64E=w294-h251-s-no?authuser=2",
					flex: 6,
				},
			],
			image: [
				{
					src: "https://lh3.googleusercontent.com/pw/AIL4fc_TgZsKgTJ8SvecwiNPVcw7IZ3Bw8rocho6KOCC6l9YgizxTchom40IZ9cenuoPuDN71FTDNniBuMteAjI7r5CjKpOiCgAbVdS8HxDEsAFBufAYitCBcyqmxVV4OGZA5RzRsMUg2WrvOv8zRoj1qpRm7w=w1280-h720-s-no?authuser=0",
				},
				{
					src: "https://lh3.googleusercontent.com/pw/AIL4fc9iFNspjkWmcKJQhLUwnLMNtPzFZNqDZigtMae_s2jIWm4gBHEdu844MzU9exNoJMcjZ9ZGqXzNElcZ7GUCgtZubXJjI8KXvu5gVg5lGjQWnnRDdsuDfzjpszcnW_cgkoSywcHMqayn1hVt_5ExnHosVqzTwkWeD4cSgAKagEf-ZjkxvC-aTDIrmDgrk1lW82fhsNqa0huKjXwdPyI0UuDyuYJFS73MWHDS9TwOEexboPoNB-2Nn0SQPew5NiNmBRihqnhBgazkkXVZ9FbvyPc-eWCLGCfJ5qhX2qMdi4wTLg4Mh2WIugAnzwnQMyF6do6Kt4jhE0_4Q8ErA9WnbWl8gqOnPRMNmHTuaDZ2DRvOuwGqgakZ4My8hsPzAPmk8ovOYNIyY68cOU5B-HYtiQMBmumj7zdg4aQJnfNQSEkJXEML-ioUgrD8_eJXFfI2YLwN1u0VFCh8hkvFiMCvZyRNnVtWQ1y1OEv5-LQ3kM2g7sf6uCZ9P0i_ua0ImccJnFsh1UC7rMVyFx5yDKPAnCtdYh90_dhcTVKWY7Z6thWMMacaRg5qnQy92UYlU8_e-oPn0iL0PUlbN9ArkJqgUHwtBY_4sAkKegsXXRV5s_cxeBLSYtrXxG35e9eps3AEIWKf_CLGVsyCP7vjJ4cLlVy4pl-EGW6kRuKPZEw4o_qc_75EE2rL0ovK1VUmNCCTvQ972pw18i7mVC7FSTnsJaT1kup82pc7TE023quDtpZ9C0AaOJnVUtZVNhXmgPkURMVTBaYaZ0VCDV_-mLPjDfu0PmZQMZ17q7CQhvMzPnRkhQpPOnQrfzReMlHVvtzFuT-xCUpxdsMPIHRdubpQopVpGaoVS7P-m58Tc8QFuWyHwQLoYuMtbMeFhFq_CCw0vpL1hhtTrnVyHs1sNVbudhAaGNMKkhfpi8_c7Oblt5bMrlgpHnwuTaVFf3E=w1626-h625-s-no?authuser=2",
				},
				{
					src: "https://lh3.googleusercontent.com/pw/AIL4fc9EUsCTs5IzeqVNpZdYA5UuHHuYntgM3BK_iQdXUOR94CUst7-ZqCZqnVZ_HIuUrdpvTMuxuooEMSUJ1PyNsauhDEuidn4grZmKu7cDZmcO4VaNPOibRsGBxM1lDREXwRA5Wi0iOsEDVS54WK20rtZYECSnDB47I3iyhAcT1wkvzUM4F7RE6TZcaKYrXdlp3WbxS4onnGKsmEFh_YNAh2Zwe6RQkeb2bbCU5xCxwys7PTrh9M-dafY-hgrEAVxjzYpFTJVkPFYwUmeDcjb2RdS5HhL9bHs1t2rTbbAj4D3323AI8w4eCjso0lghYwt-oFcbY6nDpuYNg-QBo9ftxo8p9qOU0OZGObOC8rvlRlbqpUXUeEm6I7fHWhzleZRojRswe2Ux3-C8hTFuGpA5OynUNk9x3aRg6FqGilXSFv61y-iFmEXK1svGMkOVdeVENOYRcFwLfBIio2zowl6E-5LuqrqxcvprHG6kv3El8UHP56DV5iBoGG6HCXxk6f0YRaf1778fZYFN2hZdFPoQFYFG0gxe6LpX36XcjMi46jKyiYDoh2hSnbgfKWW1dwTM9acDDGR093j5N43gdjdkd2tbVU6Leng_bDa92v-bTi16DbLjXKSMExRIH_d0sLgpa7Gn4lwtbjWRu6rV1HNz0YIOVq3szJLwXi1mGyKhkjiQcV0bEgRMuwCBhxRfjwoaH0HzuTOpP9pDhitWKVrLdi_8wjuX9PRtaxSaUjtK5Omsvw0aCIFpSCIBeRyIpqmd7Jxe1oogUXrUQU2cOhdCcFc87yQ--zLEQ3sFvKYbQxh2dqJP6bzkxRTS7r3EySo0tj2dxlCJSOHL-qZEkYMgWHb_IrU8GbC6CUOrlWp41up09X26LlBGKnJcTVEt9THHkExTgZQx76KRKvMLqwAIAbmh0taNTmwt4do-9i-SCfVmKq1_EzlRDumh2x4=w1626-h625-s-no?authuser=2",
				},
				{
					src: "https://lh3.googleusercontent.com/pw/AIL4fc-o9r0u752dhDTkMRwxXSxoB9cn7fCvD6j1lqradaH_OSSePrfmZBlIyKccHBE4AX--Kf_lg2IKQjb4h-1dG980XTX_xL7HqEBasOr7LuCYY4XvJK3MJkqeYCkmI7T8C8PZgFpU25LijgPm6-7oAau2IWI8YsIhGVo6A-3FPVjAYRDpdN7gm7pyGdzR5ijsoXt-vAoGqWKmLm7o_2ROMZlBPlrp2Ks6oKdCTQJWexwqWVBDy4Q2Rm51K5c7zmhvkxBZFAkA5F1CIQOQeO5q-B2rkUbGAqENn69l0-Y4HpFaawKWkcfkECpDRsmdN6YyL4ti5IMSqwMW8OnHNzKzztqoYyQY_-5T6T48KlagvHQNimiWeODsqWPOhX4pxrkfs0XGTe0f6loFp3bek1hlarNpA_S45mOdeZj3-nMj9hyTiJVpY2YG5-fRyzIpEAIfqgGDY2r3Ydu9GrqcCC4flHN1NiEABpNElPXEy3FMsZo7nXKMqrngUDI8PSSMHuNo8v5cuQ_PVOukGLOTE_4NB01tMcmjK3dVWxKU0h0vGkq1wYsakJJSWQL0ZK79QLXATdN6OFhHoYYXTFvMZpEkHJayBkQSEVQrkVx5kQmcrIAf8mTSQNSOeZEQsiSc_exLhiq_ov_2Qw3NEOCtH_inzkUDcqWRDRBXsB-kvuK5b5OKt9syYdszw8Wjdi_KxRAj0AG_VWD5PG8fEI21XSpd1_ljqfln8IdgV2BN85E_d3yxU2pJH5oyCQH_ZequocWY-xUGcljl80h_goaKzwZptGN-dhye6iC5c0azPPEOYYieUdJoAitSHutguxZW7-djB9lbao_OOyw9ZMMmtgbEFHWTYlId-DlOsfUfpLLtbbRq4rXk0yeyn1vtT1AJFVuCD7QBxuAQgUp5LxtzUg5aj5S43CBpccBCFRT7Et1mh8BCy0uLxj778XSBrIc=w1626-h625-s-no?authuser=2",
				},
				{
					src: "https://lh3.googleusercontent.com/pw/AIL4fc8D9CbRPoWTlyrmC5WtIpIjpN46r6nSItXwB4Tr75MlF5p5ryNYZDy_89dngNj3wu-7Sx9OmUOSx7MOY04hwCpeep4M06NPhdWPdu4B2_C-rbSPTEdwSbT8BOjdADWaHvM0nJuZlmjj30SnFHMMXOj7zG0dtnUtJwC_aqJW1aYsyXrsIdv0sc2KFHCDtLQXaGSvbGwUWCQ5vyA-kCwis3KVeDjGvtrxtegSW_Gzc0ivtyIkIA2EJdWXmdhRcTkkDC-MdtM_YNsUpScGVOn426LMLbst9gm6Y1D7Hgo652A9N6Y5vcu4ENXzaEHxHtmMA-E0eAuknNRt7Y9OBnyhfYgFmJVagDIE9mzsv-C3kf39W-0NdvB6K63uFWJyjHa9D56J1dYNpFnAF-7ypa72loqAKB2pMM4r4E6nPBveTOdzWGEniK63CmT5MWLKimpSLqC4JwOK3jyNZX9ezDpj_3QJTaYTyEYLkxkopaDMvNEG9nyweMwgXaP3s1A-STA82sx2m8SdyoPenl3AKwgRWD92gpuOL0fkV1ko3qlrpYqbQ4Ymcd1geekpkCixATG9vUDcYOs7bsIzdjL00lyeQMiEP7mHkIGQ8TVBU2Z3hjoBSVnr8lSMhwzqeqv0mP0mP8O12x4VMlHYHkkcVTWWfaXT_MvJeSWEESqPZJVUkXy4XUDhvME5eme3om9k8Up9ece5SwuWE7O0_mt2rkm0VRJzDtE0a1x7b-oosWCRNhdPO_6RcD-xuRNX83Sit5L-OnwyA28x-_ueiy1ure9C54SMepP4kbD0tpVnpkwyo42_uh6_dSAyaYQkdIH-vxiSWQZ2X8H4tsESD1hzdiJpur13x39rFfVC7WnBiSdJy1pkQVI7qXKjM5Hk-Nbs_X4gAPj20ZRhP4wAXv1ocG-BgUVjs0gWA1PE56RPuXUpGvPrt76oahE5GO_V4-0=w1626-h625-s-no?authuser=2",
				},
			],
		}),
	};
</script>
